import { useState } from "react"
import { DateTime } from "luxon"
import { PrimeSpeedRow } from "../common.types"

const useAmazonPageSpeedViewsFilters = (data: PrimeSpeedRow[] | undefined, startDate: string | null, endDate: string | null, refetch) => {
  const [showPrime, setShowPrime] = useState(false)
  const [showBolingbrook, setShowBolingbrook] = useState(false)
  const [showOversize, setOversize] = useState(false)
  const [filteredData, setFilteredData] = useState<PrimeSpeedRow[]>([])
  const [draftData, setDraftData] = useState<PrimeSpeedRow[]>([])

  const applyFilters = () => {
    if (!data) return

    const newFilteredData = data.filter((row) => {
      const isPrimeFilterPassed = showPrime ? row.isPrime : true
      const isBolingbrookFilterPassed = showBolingbrook ? row.inBolingbrook : true
      const isOversize = !showOversize || (typeof row.size === 'string' && row.size === "Oversize")

      const startDateFilterPassed = startDate
        ? row.viewDetails.some((view) => {
            const viewDate = DateTime.fromISO(view.date)
            return viewDate >= DateTime.fromISO(startDate)
          })
        : true

      const endDateFilterPassed = endDate
        ? row.viewDetails.some((view) => {
            const viewDate = DateTime.fromISO(view.date)
            return viewDate <= DateTime.fromISO(endDate)
          })
        : true

      return (
        isPrimeFilterPassed &&
        isBolingbrookFilterPassed &&
        isOversize &&
        startDateFilterPassed &&
        endDateFilterPassed
      )
    })

    setFilteredData(newFilteredData) // Update the state with filtered data
    setDraftData(newFilteredData)

    refetch()
  }

  const clearFilters = () => {
    if (!data) return

    const newFilteredData = data.filter((row) => {
      setShowPrime(false)
      setShowBolingbrook(false)
      setOversize(false)

      const startDateFilterPassed = startDate
        ? row.viewDetails.some((view) => {
            const viewDate = DateTime.fromISO(view.date)
            return viewDate >= DateTime.fromISO(startDate)
          })
        : true

      const endDateFilterPassed = endDate
        ? row.viewDetails.some((view) => {
            const viewDate = DateTime.fromISO(view.date)
            return viewDate <= DateTime.fromISO(endDate)
          })
        : true

      return (
        startDateFilterPassed &&
        endDateFilterPassed
      )
    })

    setFilteredData(newFilteredData)
    setDraftData(newFilteredData)

    refetch()
  }

  return {
    showPrime,
    setShowPrime,
    showBolingbrook,
    setShowBolingbrook,
    showOversize,
    setOversize,
    filteredData,
    draftData,
    setDraftData,
    setFilteredData,
    applyFilters,
    clearFilters
  }
}

export default useAmazonPageSpeedViewsFilters