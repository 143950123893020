import React, { createContext, useContext, useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import { useSpinner } from "../../spinner/SpinnerContext"
import useAmazonPageSpeedViewsData from "../../common/hooks/useAmazonPageSpeedViewsData"
import useAmazonPageSpeedViewsFilters from "../../common/hooks/useAmazonPageSpeedViewsFilters"
import useGraphData from "../../common/hooks/useAmazonPageSpeedViewsGraphData"
import { PrimeSpeedRow } from '../../common/common.types'
import { calculateDraftGraphSummary } from '../../common/common.helpers'
import * as XLSX from "xlsx"

// Create the context
const PageSpeedViewsContext = createContext(null);

// Context Provider
export const PageSpeedViewsProvider = ({ children }) => {
  const getPreviousWeekRange = () => {
    const now = DateTime.local().toUTC();
    const previousSunday = now.minus({ days: now.weekday % 7 + 7 }).toFormat('yyyy-MM-dd');
    const previousSaturday = now.minus({ days: now.weekday % 7 + 1 }).toFormat('yyyy-MM-dd');
    return { previousSunday, previousSaturday };
  };

  const { toggleSpinner } = useSpinner();
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(getPreviousWeekRange().previousSunday);
  const [selectedEndDate, setSelectedEndDate] = useState(getPreviousWeekRange().previousSaturday);
  const ONE_DAY_PERCENTAGE_REQUIREMENT = 10;
  const TWO_DAY_PERCENTAGE_REQUIREMENT = 45;

  const {
    isLoading,
    isPending,
    error,
    data,
    isFetching,
    handleFileChange,
    uploadFile,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    refetch,
  } = useAmazonPageSpeedViewsData(selectedStartDate, selectedEndDate);

  const {
    showPrime,
    setShowPrime,
    showBolingbrook,
    setShowBolingbrook,
    showOversize,
    setOversize,
    filteredData,
    setFilteredData,
    draftData,
    setDraftData,
    applyFilters,
    clearFilters,
  } = useAmazonPageSpeedViewsFilters(data as PrimeSpeedRow[], startDate, endDate, refetch);

  const { graphData, summaries, graphSummary, projectedSummary, setDraftGraphSummary, draftGraphSummary } = useGraphData(filteredData, startDate, endDate);

  const togglePrime = (asin: string) => {
    try {
      const updatedDraftData = draftData.map((row) => {
        if (row.asin === asin) {
          const draftPrime = row.draftPrime !== undefined ? !row.draftPrime : !row.isPrime;
          return { ...row, draftPrime }; // Only update draftPrime
        }
        return row;
      });
  
      setDraftData(updatedDraftData); // Update draft data
  
      // Calculate the new summary based on updated draft data
      const newDraftSummary = calculateDraftGraphSummary(updatedDraftData, selectedStartDate, selectedEndDate);
      setDraftGraphSummary(newDraftSummary);

    } catch (error) {
      console.error('Error in togglePrime:', error);
    }
  }

  useEffect(() => {
    setDraftData(filteredData);
  }, [filteredData])

  const handleApplyFilters = (e) => {
    e.preventDefault();
    setStartDate(selectedStartDate);
    setEndDate(selectedEndDate);
    applyFilters();
  };

  const handleClearFilters = (e) => {
    e.preventDefault();
    setStartDate(selectedStartDate);
    setEndDate(selectedEndDate);
    clearFilters();
  };

  const handleAmazonViewsExport = (e) => {
    e.preventDefault();
  
    const exportXlsx = async () => {
      try {
        const exportData = draftData.map((row) => {
          const totalViews = row.viewDetails.reduce((acc, view) => acc + view.twoDayViews + view.greaterThanTwoDayViews, 0);
          const oneDayViews = row.viewDetails.reduce((acc, view) => acc + view.oneDayViews, 0);
          const twoDayViews = row.viewDetails.reduce((acc, view) => acc + view.twoDayViews, 0);
          const oneDayPercentage = totalViews ? ((oneDayViews / totalViews) * 100) : 0;
          const twoDayPercentage = totalViews ? ((twoDayViews / totalViews) * 100) : 0;
  
          // Calculate other values such as average views per day
          const startDate = DateTime.fromISO(selectedStartDate);
          const endDate = DateTime.fromISO(selectedEndDate);
          const diffDays = endDate.diff(startDate, "days").days;
          const averageViewsPerDay = totalViews ? (totalViews / diffDays).toFixed(2) : '0';
  
          // Return an object for export, including computed fields
          return {
            ASIN: row.asin,
            SKU: row.sku,
            Title: row.title,
            Revenue: row.revenue,
            Units: row.units,
            OneDayPercentage: Number(oneDayPercentage.toFixed(2)),
            TwoDayPercentage: Number(twoDayPercentage.toFixed(2)),
            OneDayViews: oneDayViews,
            TwoDayViews: twoDayViews,
            TotalViews: totalViews,
            AverageViewsPerDay: Number(averageViewsPerDay),
            Prime: row.isPrime ? 'Yes' : 'No',
            Bolingbrook: row.inBolingbrook ? 'Yes' : 'No',
            Size: row.size,
          };
        });
  
        // Calculate the totals and averages
        const totals = {
          ASIN: 'TOTALS',
          SKU: '',
          Title: '',
          Revenue: exportData.reduce((acc, row) => acc + Number(row.Revenue || 0), 0),
          Units: exportData.reduce((acc, row) => acc + row.Units, 0),
          OneDayPercentage: Number((exportData.reduce((acc, row) => acc + row.OneDayPercentage, 0) / exportData.length).toFixed(2)),
          TwoDayPercentage: Number((exportData.reduce((acc, row) => acc + row.TwoDayPercentage, 0) / exportData.length).toFixed(2)),
          OneDayViews: exportData.reduce((acc, row) => acc + row.OneDayViews, 0),
          TwoDayViews: exportData.reduce((acc, row) => acc + row.TwoDayViews, 0),
          TotalViews: exportData.reduce((acc, row) => acc + row.TotalViews, 0),
          AverageViewsPerDay: Number((exportData.reduce((acc, row) => acc + row.AverageViewsPerDay, 0) / exportData.length).toFixed(2)),
          Prime: '',
          Bolingbrook: '',
          Size: ''
        };
  
        // Add totals to the data for export
        exportData.push(totals);
  
        // Create a worksheet and append the data
        const worksheet = XLSX.utils.json_to_sheet(exportData);
  
        // Add formulas to sum the columns in Excel (starting from row 2 to the last row, without header row)
        const lastRow = exportData.length + 1;
        worksheet[`D${lastRow}`] = { f: `SUM(D2:D${lastRow - 1})` }; // Sum for Revenue
        worksheet[`E${lastRow}`] = { f: `SUM(E2:E${lastRow - 1})` }; // Sum for Units
        worksheet[`F${lastRow}`] = { f: `IF(COUNT(F2:F${lastRow - 1}) > 0, AVERAGE(F2:F${lastRow - 1}), 0)` }; // Avg One Day Percentage
        worksheet[`G${lastRow}`] = { f: `IF(COUNT(G2:G${lastRow - 1}) > 0, AVERAGE(G2:G${lastRow - 1}), 0)` }; // Avg Two Day Percentage
        worksheet[`H${lastRow}`] = { f: `SUM(H2:H${lastRow - 1})` }; // Sum for OneDayViews
        worksheet[`I${lastRow}`] = { f: `SUM(I2:I${lastRow - 1})` }; // Sum for TwoDayViews
        worksheet[`J${lastRow}`] = { f: `SUM(J2:J${lastRow - 1})` }; // Sum for TotalViews
        worksheet[`K${lastRow}`] = { f: `SUM(K2:K${lastRow - 1})` }; // Sum for AvgViews
  
        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'AmazonPageSpeedViews');
  
        // Export the workbook as an xlsx file
        XLSX.writeFile(workbook, `Amazon Page Speed Views_${selectedStartDate}_to_${selectedEndDate}.xlsx`);
      } catch (error) {
        console.error("Error exporting data to xlsx:", error);
      }
    };
  
    exportXlsx();
  };

  const isDataLoading = isPending || isFetching || isLoading;

  useEffect(() => {
    toggleSpinner(isDataLoading);
  }, [isDataLoading]);

  useEffect(() => {
    if (data) {
      clearFilters();
    }
  }, [data]);

  // Provide all the state and handlers as context value
  return (
    <PageSpeedViewsContext.Provider
      value={{
        globalFilter,
        setGlobalFilter,
        selectedStartDate,
        setSelectedStartDate,
        selectedEndDate,
        setSelectedEndDate,
        ONE_DAY_PERCENTAGE_REQUIREMENT,
        TWO_DAY_PERCENTAGE_REQUIREMENT,
        isLoading,
        error,
        data,
        handleFileChange,
        uploadFile,
        showPrime,
        setShowPrime,
        showBolingbrook,
        setShowBolingbrook,
        showOversize,
        setOversize,
        filteredData,
        setFilteredData,
        applyFilters,
        clearFilters,
        graphData,
        summaries,
        graphSummary,
        draftGraphSummary,
        setDraftGraphSummary,
        handleApplyFilters,
        handleClearFilters,
        handleAmazonViewsExport,
        togglePrime,
        draftData,
        setDraftData,
        isDataLoading,
        projectedSummary
      }}
    >
      {children}
    </PageSpeedViewsContext.Provider>
  );
};

// Custom hook to use the context
export const usePageSpeedViewsContext = () => {
  const context = useContext(PageSpeedViewsContext);
  if (!context) {
    throw new Error('usePageSpeedViewsContext must be used within a PageSpeedViewsProvider');
  }
  return context;
};