import { useEffect, useState } from "react"
import { calculateGraphData, calculateGraphSummary, calculateViewPercentages, calculateSummaries, calculateProjectedSummary } from "../common.helpers"
import { PrimeSpeedRow, GraphDataPoint } from "../common.types"

const useGraphData = (filteredData: PrimeSpeedRow[], startDate: string | null, endDate: string | null) => {
  const [graphData, setGraphData] = useState<GraphDataPoint[]>([])
  const [summaries, setSummaries] = useState({
    uniqueASINs: 0,
    uniqueOversizeASINs: 0,
    totalTwoDaysOrLessOversize: 0,
    totalGreaterThanTwoDaysOversize: 0,
    totalRevenue: 0,
    totalUnits: 0,
    percentageTwoDaysOrLessOversize: 0,
    revenueTwoDaysOrLess: 0,
    unitsTwoDaysOrLess: 0,
    oversizeOnlyRevenue: 0,
    oversizeOnlyUnits: 0,
  })
  const [graphSummary, setGraphSummary] = useState({
    oneDayPercent: 0,
    twoDayPercent: 0,
    greaterThanTwoDayPercent: 0,
    oneDayViews: 0,
    twoDayViews: 0,
    greaterThanTwoDayViews: 0,
    totalViews: 0
  })
  const [draftGraphSummary, setDraftGraphSummary] = useState({
    oneDayPercent: 0,
    twoDayPercent: 0,
    greaterThanTwoDayPercent: 0,
    oneDayViews: 0,
    twoDayViews: 0,
    greaterThanTwoDayViews: 0,
    totalViews: 0
  })
  const [projectedSummary, setProjectedSummary] = useState({
    oneDayPercent: 0,
    twoDayPercent: 0,
    greaterThanTwoDayPercent: 0,
    oneDayViews: 0,
    twoDayViews: 0,
    greaterThanTwoDayViews: 0,
    totalViews: 0
  })

  useEffect(() => {
    if (filteredData) {
      const updatedRows = calculateViewPercentages(filteredData, startDate, endDate)
      setSummaries(calculateSummaries(updatedRows, startDate, endDate))
      setGraphData(calculateGraphData(filteredData, startDate, endDate))
      setGraphSummary(calculateGraphSummary(filteredData, startDate, endDate))
      setDraftGraphSummary(calculateGraphSummary(filteredData, startDate, endDate))
      setProjectedSummary(calculateProjectedSummary(filteredData))
    }
  }, [filteredData, startDate, endDate])

  return { graphData, summaries, graphSummary, projectedSummary, setDraftGraphSummary, draftGraphSummary, }
}

export default useGraphData