import React, { useMemo, useState, useEffect } from 'react';
import { PrimeSpeedRow } from '../../common/common.types'; // Assume you have this imported from your types
import { DateTime } from 'luxon';
import { faCheckCircle, faCircleCheck, faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox } from '@fortawesome/free-solid-svg-icons';
import { faAmazon } from '@fortawesome/free-brands-svg-icons';

type ASINRowProps = {
  row: PrimeSpeedRow;
  selectedStartDate: string
  selectedEndDate: string
  ONE_DAY_PERCENTAGE_REQUIREMENT: number
  TWO_DAY_PERCENTAGE_REQUIREMENT: number
  togglePrime: (asin: string) => void;
};

const ASINRowComponent: React.FC<ASINRowProps> = ({ row, selectedStartDate, selectedEndDate, ONE_DAY_PERCENTAGE_REQUIREMENT, TWO_DAY_PERCENTAGE_REQUIREMENT, togglePrime }) => {
  const totalViews = row.viewDetails.reduce((acc, view) => acc + view.twoDayViews + view.greaterThanTwoDayViews, 0)
  const oneDayViews = row.viewDetails.reduce((acc, view) => acc + view.oneDayViews, 0)
  const twoDayViews = row.viewDetails.reduce((acc, view) => acc + view.twoDayViews, 0)
  const startDate = DateTime.fromISO(selectedStartDate);
  const endDate = DateTime.fromISO(selectedEndDate);
  const diffDays =  endDate.diff(startDate, "days").days
  const oneDayPercentage = totalViews ? ((oneDayViews / totalViews) * 100) : 0
  const twoDayPercentage = totalViews ? ((twoDayViews / totalViews) * 100) : 0
  const highlightStyleOne = oneDayPercentage >= ONE_DAY_PERCENTAGE_REQUIREMENT ? 'bg-success text-light' : ''
  const highlightStyleTwo = twoDayPercentage >= TWO_DAY_PERCENTAGE_REQUIREMENT ? 'bg-success text-light' : ''

  const isPrime = row.draftPrime !== undefined ? row.draftPrime : row.isPrime

  return (
    <tr>
      <td className={`text-center`}>{row.asin}</td>
      <td className={`text-center`}>{row.sku}</td>
      <td className={`text-center`}>{row.title}</td>
      <td className={`text-center`}>${row.revenue}</td>
      <td className={`text-center`}>{row.units}</td>
      <td className={`text-center ${highlightStyleOne}`}>{oneDayPercentage.toFixed(2)}%</td>
      <td className={`text-center ${highlightStyleTwo}`}>{twoDayPercentage.toFixed(2)}%</td>
      <td className={`text-center`}>{oneDayViews}</td>
      <td className={`text-center`}>{twoDayViews}</td>
      <td className={`text-center`}>{totalViews}</td>
      <td className={`text-center`}>{totalViews ? (totalViews/diffDays).toFixed(2) : 0}</td>
      <td className={`text-center`}>{row.isPrime ? <FontAwesomeIcon icon={faAmazon} />: ''}</td>
      <td className={`text-center`} onClick={() => togglePrime(row.asin)} style={{ cursor: 'pointer' }}>
        {isPrime ? (
                <FontAwesomeIcon icon={faCircleCheck} style={{ color: 'green' }} />
              ) : (
                <FontAwesomeIcon icon={faCircleXmark} style={{ color: 'red' }} />
              )}
      </td>
      <td className={`text-center`}>{row.inBolingbrook ? <FontAwesomeIcon icon={faBox} /> : ''}</td>
      <td className={`text-center`}>{row.size}</td>
    </tr>
  );
};

export default ASINRowComponent;